import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './pages/App';
import Dashboard from './pages/Dashboard';
import * as serviceWorker from './serviceWorker';



ReactDOM.render(
  page(),
  document.getElementById('root')
);

function page(){
  if(sessionStorage.getItem("user") != null){
      var cpage = sessionStorage.getItem("page");
      switch(cpage){
        case "dashboard":
          return <Dashboard/>
        default:
          return <App/>
      }
  }else{
    return <App/>;
  }
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
