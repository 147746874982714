import React from 'react';
import './App.css';
import './../assets/modules/bootstrap/css/bootstrap.css';
import './../assets/modules/bootstrap/css/bootstrap.min.css';
import './../assets/modules/fontawesome/css/all.min.css';
import './../assets/modules/bootstrap-social/bootstrap-social.css';
import './../mobile.css';
import './../assets/css/components.css';
import './../assets/css/components.min.css';
import MetaTags from 'react-meta-tags';
import Dashboard from './Dashboard';
import ReactDOM from 'react-dom';
import {URL} from './General';

const fetch = require('node-fetch');

class App extends React.Component {
    constructor(props) {
      super(props);
      this.state = { 
          uname: '',
          pass : '',
          pin : '',
          error : '',
        };

        this.handleChange = this.handleChange.bind(this);
        this.login = this.login.bind(this);
  
    }

    handleChange(event) {
        this.setState({ [event.target.name] : event.target.value});
    }

    render(){
        return (
            <div id="app" className='App' style={{ height:'100vh', backgroundColor:'#dadada' }}>
                <MetaTags>
                    <title>Orange</title>
                    <meta name="description" content="Orange System" />
                    <meta property="og:title" content="Orange" />
                    <meta content="width=device-width, initial-scale=1, maximum-scale=1, shrink-to-fit=no" name="viewport"/>
                </MetaTags>
                <section className="section">
                    <div className="container mt-5"  >
                        <div className="row">
                            <div className="col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
                                <div className="card card-primary" >
                                    <div className="card-header" >
                                        <h4>Login</h4>
                                    </div>

                                    <div className="card-body">
                                        <div className="form-group">
                                            <label htmlFor="username">User ID</label>
                                            <input id="username" type="text" value={this.state.uname} className="form-control" name="uname" tabIndex="1"
                                                required autoFocus onChange={this.handleChange} />
                                            <div className="invalid-feedback">
                                                Please fill in your User ID
                                                </div>
                                        </div>

                                        <div className="form-group">
                                            <div className="d-block">
                                                <label htmlFor="password" className="control-label">Password</label>
                                            </div>
                                            <input id="password" type="password" value={this.state.pass} className="form-control" name="pass"
                                                tabIndex="2" required autoFocus onChange={this.handleChange} />
                                            <div className="invalid-feedback">
                                                please fill in your password
                                                </div>
                                        </div>

                                        <div className="form-group">
                                            <div className="d-block">
                                                <label htmlFor="pin" className="control-label">Pin</label>
                                            </div>
                                            <input id="pin" type="text" value={this.state.pin} className="form-control" name="pin"
                                                tabIndex="3" required autoFocus onChange={this.handleChange}  autocomplete="off"/>
                                            <div className="invalid-feedback">
                                                please fill in your Pin
                                                </div>
                                        </div>
                                        <h5 id="message" style={{ color: 'red' }}>{this.state.error} </h5>


                                        <div className="form-group" >
                                            <button id="loginBtn" type="submit" value="submit" className="btn btn-primary btn-lg btn-block" tabIndex="4"
                                                style={{ backgroundColor: '#0b9c29' }} onClick={this.login}>
                                                Login
                                                </button>
                                        </div>
                                    </div>

                                </div>

                            </div>
                            <div className="simple-footer">
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }

    login(){
        const body = {
            "action":"applogin",
            uname : this.state.uname,
            pwd :this.state.pass,
            pin:this.state.pin
          };
     
        fetch(URL, {
                method: 'POST',
                body:    JSON.stringify(body)
            }).then(res => res.json())
            .then(json => {
                if(json.success){
                    sessionStorage.setItem("user", json.data[0].id);
                    sessionStorage.setItem("name", json.data[0].first_name);
                    sessionStorage.setItem("name_u", json.data[0].username);
                    sessionStorage.setItem("page", "dashboard");
                    ReactDOM.render(
                        <Dashboard/>,
                        document.getElementById('root')
                    );
                }
                else{
                    this.setState({error : "Login Failed, Please Try Again"})
                }
            })
            .catch(e =>console.log(e));
    }
}


export default App;
