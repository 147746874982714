import React from 'react';
import ReactDOM from 'react-dom';
import Dashboard from './Dashboard';
import App from './App';

export function back() {
	ReactDOM.render(<Dashboard />, document.getElementById('root'));
}

export const version = 'v1.3 dev';
//export const URL = "https://www.77fkhr.com/v1/live/api.php";
// export const URL = "https://www.77fkhr.com/v1/production/api.php";
export const URL = 'https://demoweb.kennethraj.net/api.php';
export const debug = false;

export function logout() {
	sessionStorage.setItem('user', '');
	sessionStorage.setItem('page', '');
	ReactDOM.render(<App />, document.getElementById('root'));
}

export function getbet(re) {
	var bet = ' ';
	var type = re.betting_type.split('|')[0];
	if (type !== 'Normal') bet = ' (' + type + ') ';
	if (type == '4A') bet = ' (' + re.A4_type + ') ';
	return bet;
}

export function format(request) {
	var dict = {};
	var grandTotal = {};

	for (var r of request.data) {
		//make receipt number findable
		if (!dict[r.receipt_number]) {
			dict[r.receipt_number] = [];
		}
		dict[r.receipt_number].push(r);

		if (!grandTotal[r.receipt_number]) {
			grandTotal[r.receipt_number] = 0;
		}

		if (r.sold_out == 0) {
			//calculate total for each receipt
			var rt =
				Number(r.big_amount) +
				Number(r.small_amount) +
				Number(r.A4_amount) +
				Number(r.ABC_amount) +
				Number(r.A_amount) +
				Number(r.D5_amount) +
				Number(r.D6_amount);
			if (
				(r.betting_type.split('|')[0].toLowerCase() == 'ibox' && r.ori == 1) ||
				r.betting_type.split('|')[0].toLowerCase() != 'ibox'
			)
				grandTotal[r.receipt_number] += rt;
		}
	}

	for (var rn in dict) {
		var prevD = '';
		var prevSym = '';
		var rp = '';
		rp += sessionStorage.getItem('name_u') + '<br/>';
		rp += rn + '<br/><br/>';
		var byd = {};
		var active;
		for (var dd of dict[rn]) {
			if (!byd[dd.draw_date.split(' ')[0]]) {
				byd[dd.draw_date.split(' ')[0]] = [];
			}
			byd[dd.draw_date.split(' ')[0]].push(dd);
		}

		var buystring;
		for (var arr in byd) {
			for (var er of byd[arr]) {
				if (er.ori == 1) {
					buystring = er.request_string;
					active = er.active == 1 ? true : false;
					if (er.draw_symbol.startsWith('_')) er.draw_symbol = 'T';
					if (prevD == '') {
						prevD = er.draw_date.split(' ')[0];
						rp += prevD + '<br/>';
					} else {
						if (prevD != er.draw_date.split(' ')[0]) {
							prevD = er.draw_date.split(' ')[0];
							rp += '<br/>' + prevD.split(' ')[0] + '<br/>';
						}
					}

					//var type = ;
					var sym = er.draw_symbol;
					if (prevSym != sym) {
						rp += sym + '<br/>';
						prevSym = sym;
					}
					if (prevSym === '' && prevSym != sym) {
						rp += sym + '<br/>';
					}
					if (er.sold_out == 0) rp += er.betting_number + getbet(er) + ' = ' + getPrice(er) + '<br/>';
					else rp += er.betting_number + ' = S.O <br/>';
				}
			}
		}
		rp += '********************<br/>';
		rp += '=>   GT: ' + Number(grandTotal[rn]).toFixed(2) + '<br/>';
		rp += '********************';

		if (!active) {
			rp =
				'------------------------------------------------<br/>(CANCELED)<br/>------------------------------------------------<br/>' +
				rp;
		}
		dict[rn] = { receipt: rp, active: active, receiptNumber: rn, request_string: buystring };
	}

	//return dict['41589026383'];
	return dict;
}

function getPrice(er) {
	var line = '';
	if (er.big_amount != 0) {
		line += 'B' + Number(er.big_amount).toFixed(2);
		line += '&nbsp;&nbsp;';
	}
	if (er.small_amount != 0) {
		line += 'S' + Number(er.small_amount).toFixed(2);
		line += '&nbsp;&nbsp;';
	}
	if (er.A4_amount != 0) {
		line = Number(er.A4_amount).toFixed(2);
		line += '&nbsp;&nbsp;';
	}
	if (er.A_amount != 0) {
		line = Number(er.A_amount).toFixed(2);
		line += '&nbsp;&nbsp;';
	}
	if (er.ABC_amount != 0) {
		line = Number(er.ABC_amount).toFixed(2);
		line += '&nbsp;&nbsp;';
	}
	if (er.D5_amount != 0) {
		line = Number(er.D5_amount).toFixed(2);
		line += '&nbsp;&nbsp;';
	}
	if (er.D6_amount != 0) {
		line = Number(er.D6_amount).toFixed(2);
		line += '&nbsp;&nbsp;';
	}

	return line;
}
