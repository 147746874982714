import React from 'react';
import { Component } from 'react';
import './App.css';
import './../assets/modules/bootstrap/css/bootstrap.css';
import './../assets/modules/bootstrap/css/bootstrap.min.css';
import './../assets/modules/fontawesome/css/all.min.css';
import './../assets/modules/bootstrap-social/bootstrap-social.css';
import './../mobile.css';
import './../assets/css/components.css';
import './../assets/css/components.min.css';
import { back,URL } from './General';
import Carousel from 'react-bootstrap/Carousel'
import himg from './../mobile-asset/logo-img/logo_gd.png';
import pimg from './../mobile-asset/logo-img/logo_damacai.png';
import wimg from './../mobile-asset/logo-img/logo_cash.png';
import mimg from './../mobile-asset/logo-img/logo_magnum.png';
import timg from './../mobile-asset/logo-img/logo_sportstoto.png';
import simg from './../mobile-asset/logo-img/logo_singapore.png';
import bimg from './../mobile-asset/logo-img/logo_sabah.png';
import kimg from './../mobile-asset/logo-img/logo_sandakan.png';
import nimg from './../mobile-asset/logo-img/logo_newwin.png';

class Result extends Component {
    constructor(props) {
        super(props);
        this.state = {
            appmsg: "",
            date:"",
        };

        this.getresult = this.getresult.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        this.setState({ [event.target.name] : event.target.value});
    }

    render() {
        return (<div>
            <div className="banner">
                <p>Result</p>
            </div>
            <div className="container" style={{ margin: '10px' }}>
                <div className="row" >
                    <div className="col">
                        <button variant="secondary" onClick={() => { 
                            back();
                        }}>Back</button>
                    </div>
                </div>
            </div>
            <div className="container mt-5">
                <div className="form-group" style={{ marginTop: '10px' }}>
                    <div className=" row">
                        <div className="col-8"><input type="date" name="date" value={this.state.date} onChange={ this.handleChange } className="form-control" /></div>
                        <div className="col-4">
                            <button id="loginBtn" className="btn btn-primary btn-md btn-block"
                                style={{ backgroundColor: '#0b9c29' }} value="submit" type="submit" onClick={() =>{ this.getresult() }}>Refresh</button>
                        </div>
                    </div>
                </div>
                    <div>{this.state.error == null ? this.state.result : this.state.error}</div>
                    <br/>
                    <br/>
                    <br/>
            </div>
            </div>);
    }

    getresult(){
        const body = {
            action : "findAllResult",
            draw_date : this.state.date
          };
     
        fetch(URL, {
                method: 'POST',
                body:    JSON.stringify(body)
            }).then(res => res.json())
            .then(json => {
                if(json.success){
                    this.setState({result : this.getview(json.data), error: null});
                }
                else{
                    this.setState({error : <h5 id="message" style={{ color: 'red' }}>Failed, Please Try Again</h5>})
                }
            })
            .catch(e =>console.log(e));
    }

    getview(data){
        var citems = [];
        var map = {};
        for(var i of data){
            map[i.draw_symbol] = i;
        }
        for(var item of data){
            if(item.draw_symbol === "T"){
                item.d5_1 = map["_5D"].place_1;
                item.d5_2 = map["_5D"].place_2;
                item.d5_3 = map["_5D"].place_3;
                item.d5_4 = "#" + map["_5D"].place_1.substring(1, 5);
                item.d5_5 = "##" + map["_5D"].place_1.substring(2, 5);
                item.d5_6 = "###" + map["_5D"].place_1.substring(3, 5);
                item.d6_1 =  map["_6D"].place_1
                item.d6_2 =  "#" + map["_6D"].place_1.substring(1, 6)  + "  -||-  " + map["_6D"].place_1.substring(0, 5)+"#" ;
                item.d6_3 =  "##" + map["_6D"].place_1.substring(2, 6)  + "  -||-  " + map["_6D"].place_1.substring(0, 4)+"##" ;
                item.d6_4 =  "###" + map["_6D"].place_1.substring(3, 6)  + "  -||-  " + map["_6D"].place_1.substring(0, 3)+"###" ;
                item.d6_5 =  "####" + map["_6D"].place_1.substring(4, 6)  + "  -||-  " + map["_6D"].place_1.substring(0, 2)+"####" ;
            }
            var img = this.getimage(item.draw_symbol);
            if(img != null){
                citems.push(<Carousel.Item >{this.Getdraw(item, img)}</Carousel.Item>)
            }
        }
        return (
            <Carousel interval={null} indicators={false}>
                { citems }
            </Carousel>
            );
    }

    Getdraw(item, img){
        var sp1 = [];
        var sp2 = [];
        var con1 = [];
        var con2 = [];
        for(var i=0;i<10;i++) {
            if(i>4) {
                sp2.push(<td>{item['special_'+(i+1)]}</td>);
                con2.push(<td>{item['consolation_'+(i+1)]}</td>);
            }
            else {
                sp1.push(<td>{item['special_'+(i+1)]}</td>);
                con1.push(<td>{item['consolation_'+(i+1)]}</td>);
            }
        }
        return (<div >
            <div className="container">
                <div className="row justify-content-center">
                    <img src={img} alt="logo" width="50" />
                </div>
                <div>
                    <p>{item.draw_date}</p>
                </div>
            </div>
            <div className="container text-center">
                <div className="row">
                    <div className="col lightGrey">
                        <h3>1st Prize</h3>
                    </div>
                    <div className="col">
                        <h3>{item.place_1}</h3>
                    </div>
                </div>
                <div className="row">
                    <div className="col lightGrey">
                        <h3>2nd Prize</h3>
                    </div>
                    <div className="col">
                        <h3>{item.place_2}</h3>
                    </div>
                </div>
                <div className="row">
                    <div className="col lightGrey">
                        <h3>3rd Prize</h3>
                    </div>
                    <div className="col">
                        <h3>{item.place_3}</h3>
                    </div>
                </div>
            </div>
            <div className="container lightGrey" style={{ marginTop: '30px' }}>
                <h3>Special</h3>
                <table style={{ color:"black"}}>
                    <tr >
                        {sp1}
                    </tr>
                    <tr>
                        {sp2}
                    </tr>
                    <tr >
                        <td colspan="2" style={{textAlign: 'right'}}>{item['special_11']}</td>
                        <td >{item['special_12']}</td>
                        <td colspan="2" style={{textAlign: 'left'}}>{item['special_13']}</td>
                    </tr>
                </table>
            </div>
            <div className="container lightGrey" style={{ marginTop: '30px' }}>
                <h3>Consolation</h3>
                <table style={{ textAlign: 'center', color:"black" }}>
                    <tr>
                        {con1}
                    </tr>
                    <tr>
                        {con2}
                    </tr>
                </table>
            </div>
        {   item.draw_symbol !== "T" ? <br/> :
            <div>
                <div className="container lightGrey" style={{ marginTop: '30px' }}>
                    <h3>5D</h3>
                    <table style={{ textAlign: 'left', color:"black" }}>
                        <tr>
                            <td>1st :</td> <td>{item.d5_1}</td>
                        </tr>
                        <tr>
                            <td>2nd :</td> <td>{item.d5_2}</td>
                        </tr>
                        <tr>
                            <td>3rd :</td> <td>{item.d5_3}</td>
                        </tr>
                        <tr>
                            <td>4th :</td> <td>{item.d5_4}</td>
                        </tr>
                        <tr>
                            <td>5th :</td> <td>{item.d5_5}</td>
                        </tr>
                        <tr>
                            <td>6th :</td> <td>{item.d5_6}</td>
                        </tr>
                    </table>
                </div>

                <div className="container lightGrey" style={{ marginTop: '30px' }}>
                    <h3>6D</h3>
                    <table style={{ textAlign: 'center', color: "black" }}>
                        <tr>
                            <td>1st :</td> <td>{item.d6_1}</td>
                        </tr>
                        <tr>
                            <td>2nd :</td> <td>{item.d6_2}</td>
                        </tr>
                        <tr>
                            <td>3rd :</td> <td>{item.d6_3}</td>
                        </tr>
                        <tr>
                            <td>4th :</td> <td>{item.d6_4}</td>
                        </tr>
                        <tr>
                            <td>5th :</td> <td>{item.d6_5}</td>
                        </tr>
                    </table>
                </div>
            </div>
        }
        </div>);
    }

    getimage(symbol){
        switch (symbol) {
            case "H":
                return himg;
            case "M":
                return mimg;
            case "T":
                return timg;
            case "P":
                return pimg;
            case "K":
                return kimg;
            case "S":
                return simg;
            case "W":
                return wimg;
            case "B":
                return bimg;
            case "N":
                return nimg;
            default:
                return null;
        }
    }

}

export default Result;