import React from 'react';
import { Component } from 'react';
import './App.css';
import './../assets/modules/bootstrap/css/bootstrap.css';
import './../assets/modules/bootstrap/css/bootstrap.min.css';
import './../assets/modules/fontawesome/css/all.min.css';
import './../assets/modules/bootstrap-social/bootstrap-social.css';
import './../mobile.css';
import './../assets/css/components.css';
import './../assets/css/components.min.css';
import {back} from './General';

//import MetaTags from 'react-meta-tags';

//const fetch = require('node-fetch');

class Help extends Component {
    constructor(props) {
        super(props);
        this.state = {
            help: "",
        };

    }

    render() {
        return(
            <div>
            <div className="banner">
                <p>Help</p>
            </div>
            <div className="container" style={{ margin: '10px' }}>
                <div className="row" >
                    <div className="col">
                        <button variant="secondary" onClick={() => { 
                            back();
                        }}>Back</button>
                    </div>
                </div>
            </div>
                <div className="container mt-5">
                    <div className="card card-primary" style={{ borderRadius: '20px' }}>
                        <div className="card-body">
                        A Comm: 32.00% <br/>
                        1st : 645.00 <br/>
                        <br/>
                        ABC Comm: 32.00% <br/>
                        1st : 215.00 <br/>
                        2nd : 215.00 <br/>
                        3rd : 215.00 <br/>
                        <br/>
                        BIG Comm: 32.00% <br/>
                        1st : 2,500.00 <br/>
                        2nd : 1,000.00 <br/>
                        3rd : 500.00 <br/>
                        4th : 200.00 <br/>
                        5th : 60.00 <br/>
                        <br/>
                        SMALL Comm: 32.00%<br/>
                        1st : 3,500.00 <br/>
                        2nd : 2,000.00 <br/>
                        3rd : 1,000.00 <br/>
                        <br/>
                        4A Comm: 32.00% <br/>
                        1st : 6,000.00 <br/>
                        <br/>
                        5D Comm: 15.00% <br/>
                        1st : 16,500.00 <br/>
                        2nd : 5,500.00 <br/>
                        3rd : 3,300.00 <br/>
                        4th : 550.00 <br/>
                        5th : 22.00 <br/>
                        6th : 5.50 <br/>
                        <br/>
                        6D Comm: 15.00% <br/>
                        1st : 110,000.00 <br/>
                        2nd : 3,300.00 <br/>
                        3rd : 330.00 <br/>
                        4th : 33.00<br/>
                        5th : 4.40 <br/>
                        <br/>
                        IBOX Comm : 32.00%<br/>
                        Ibox 24<br/>
                        1st : 109.38<br/>
                        2nd : 43.75<br/>
                        3rd : 21.88<br/>
                        4th : 8.75<br/>
                        5th : 2.63<br/>
                        <br/>
                        Ibox 12<br/>
                        1st : 218.75<br/>
                        2nd : 87.50<br/>
                        3rd : 43.75<br/>
                        4th : 17.50<br/>
                        5th : 5.25<br/>
                        <br/>
                        Ibox 6<br/>
                        1st : 437.50<br/>
                        2nd : 175.00<br/>
                        3rd : 87.50<br/>
                        4th : 35.00<br/>
                        5th : 10.50<br/>
                        <br/>
                        Ibox 4<br/>
                        1st : 656.25<br/>
                        2nd : 262.50<br/>
                        3rd : 131.25<br/>
                        4th : 52.50<br/>
                        5th : 15.75"
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Help;