import React from 'react';
import Carousel from 'react-bootstrap/Carousel'
import parse from 'html-react-parser';
import {URL, back, format} from './General';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Common from './Common';
import Buy from './Buy';
import ReactDOM from 'react-dom';
import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert';

export default class ReceiptPager extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show : false,
            alertShow : false
        };
        
        this.void = this.void.bind(this);
        this.closeAlert = this.closeAlert.bind(this);
    }
    render() {
        return (
            <div>
                <div className="container" style={{ margin: '10px' }}>
                    <div className="row" >
                        <div className="col">
                            <button variant="secondary" onClick={() => {
                                back();
                            }}>Back</button>
                        </div>
                    </div>
                </div>
                <div class="container mt-2">
                    <Carousel interval={null} indicators={false}>
                        {Object.keys(this.props.receipts).map(key => 
                            <Carousel.Item title={key}>
                                <div class="card card-primary"
                                    style={{ borderRadius: '10px', height: '85vh', overflow: 'scroll', backgroundColor: this.props.receipts[key].active == true ? null : '#ff000080' }}>
                                    <div class="card-body">
                                        <p>{parse(this.props.receipts[key].receipt)}</p>
                                    </div>
                                </div>
                                <CopyToClipboard text={this.props.receipts[key].receipt.split('<br/>').join("\n").split('&nbsp;').join("\t")}>
                                    <button id="loginBtn" class="btn btn-primary btn-lg btn-block mt-2" style={{ backgroundColor: '#0b9c29' }}>
                                        Copy
                                    </button>
                                </CopyToClipboard>
                                <Alert show={this.state.alertShow}  variant="danger">
                                    {this.state.error}
                                </Alert>
                                {this.state.show ?  <Spinner animation="border" variant="success" /> : 
                                    <button id="voidbtn" class="btn btn-primary btn-lg btn-block mt-2" style={{ backgroundColor: '#0b9c29' }} 
                                    onClick={()=> this.void(this.props.receipts[key].receiptNumber)}>
                                        Void
                                    </button>
                                }
                                
                                <button id="voidbtn" class="btn btn-primary btn-lg btn-block mt-2" style={{ backgroundColor: '#0b9c29' }} 
                                    onClick={()=>{
                                        ReactDOM.render(
                                            <Buy buystring={this.props.receipts[key].request_string}/>,
                                            document.getElementById('root')
                                        );
                                    }}>
                                        Buy again
                                </button>
                                <br/>
                                <br/>
                            </Carousel.Item>
                            )}
                    </Carousel>
                </div>
            </div>
        );
    }

    closeAlert(){
        this.setState({ alertShow: false});
    }

    void(receiptNumber){
        this.setState({show : true});
        const body = {
            action : "voidBet",
            uid: sessionStorage.getItem("user"),
            receipt_number : receiptNumber
          };
        fetch(URL, {
                method: 'POST',
                body:    JSON.stringify(body)
            }).then(res => res.json())
            .then(json => {
                if(json.success){
                    this.setState({error : ""});
                    var voided = format(json)[receiptNumber];
                    ReactDOM.render(
                        <Common msg={voided.receipt} active={voided.active}/>,
                        document.getElementById('root')
                    );
                }
                else{
                    //alert("failed");
                    this.setState({ show: false, error : "Void Failed, Please Try Again {" + json.error.msg + "}", alertShow : true }, () => {
                        setTimeout(() => this.closeAlert() , 3000);
                    });
                }
            })
            .catch(e => console.log(e));
    }
}