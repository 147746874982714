import React from 'react';
import { Component } from 'react';
import './App.css';
import './../assets/modules/bootstrap/css/bootstrap.css';
import './../assets/modules/bootstrap/css/bootstrap.min.css';
import './../assets/modules/fontawesome/css/all.min.css';
import './../assets/modules/bootstrap-social/bootstrap-social.css';
import './../mobile.css';
import './../assets/css/components.css';
import './../assets/css/components.min.css';
import { back } from './General';
import parse from 'html-react-parser';
import { CopyToClipboard } from 'react-copy-to-clipboard';

//import MetaTags from 'react-meta-tags';

//const fetch = require('node-fetch');

class Common extends Component {
	constructor(props) {
		const p = { ...props };
		if (!p.active && typeof p.active === 'undefined') p.active = true;
		super(p);
		var stringmsg = this.props.msg;
		stringmsg = stringmsg.split('<br/>').join('\n').split('&nbsp;').join('\t');
		this.state = {
			appmsg: '',
			stringmsg: stringmsg,
		};
	}

	render() {
		return (
			<div>
				<div className='container' style={{ margin: '10px' }}>
					<div className='row'>
						<div className='col'>
							<button
								variant='secondary'
								onClick={() => {
									back();
								}}>
								Back
							</button>
						</div>
					</div>
				</div>
				<div class='container mt-2'>
					<div
						class='card card-primary'
						style={{
							borderRadius: '10px',
							height: '85vh',
							overflow: 'scroll',
							backgroundColor: this.props.active == true ? null : '#ff000080',
						}}>
						<div class='card-body'>
							<p>{parse(this.props.msg)}</p>
						</div>
					</div>
					<CopyToClipboard text={this.state.stringmsg}>
						<button id='loginBtn' class='btn btn-primary btn-lg btn-block mt-2' style={{ backgroundColor: '#0b9c29' }}>
							Copy
						</button>
					</CopyToClipboard>
				</div>
			</div>
		);
	}
}

export default Common;
