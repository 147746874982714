import React from 'react';
import { Component } from 'react';
import './App.css';
import './../assets/modules/bootstrap/css/bootstrap.css';
import './../assets/modules/bootstrap/css/bootstrap.min.css';
import './../assets/modules/fontawesome/css/all.min.css';
import './../assets/modules/bootstrap-social/bootstrap-social.css';
import './../mobile.css';
import './../assets/css/components.css';
import './../assets/css/components.min.css';
import { back, URL, format } from './General';
import ReceiptPager from'./ReceiptPager';
import ReactDOM from 'react-dom';
import Spinner from 'react-bootstrap/Spinner';

class Receipt extends Component {
    constructor(props) {
        super(props);
        this.state = {
            type: "bet",
            show : false

        };
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        this.setState({ [event.target.name] : event.target.value});
    }


    findbet(){
        this.setState({show : true});
        const body = {
            action :"findBetData",
            type : this.state.type,
            requested_date :this.state.date,
            uid: sessionStorage.getItem("user")
          };
        fetch(URL, {
                method: 'POST',
                body:    JSON.stringify(body)
            }).then(res => res.json())
            .then(json => {
                if(json.success){
                    this.setState({error : null});
                    ReactDOM.render(
                        <ReceiptPager receipts={{...format(json)}}/>,
                        document.getElementById('root')
                    );
                }
                else{
                    this.setState({show : false, error : "Failed, Please Try Again"})
                }
            })
            .catch(e => console.log(e));
    }

    render() {
        return(
        <div> 
            <div className="banner">
                <p>Receipt/Void</p>
            </div>
            <div className="container" style={{ margin: '10px' }}>
                <div className="row" >
                    <div className="col">
                        <button variant="secondary" onClick={() => {
                            back();
                        }}>Back</button>
                    </div>
                </div>
            </div>

            <div className="container mt-5">
                <div className="card card-primary" style={{ borderRadius: '10px' }}>
                    <div className="card-body">
                        <div className="row">
                            <input type="date" name="date" className="form-control" value={ this.state.date } onChange={this.handleChange}/>
                        </div>
                        <div style={{ marginTop: '10px' }}>
                            <div className="radio row" >
                                <div className="col-2"><input type="radio" name="type" value="bet" checked={ this.state.type === "bet" } className="form-check-input" onChange={ this.handleChange } /></div>
                                <div className="col-10"><label>Bet Date</label></div>

                            </div>
                            <div className="radio row" >
                                <div className="col-2"><input type="radio" name="type" value="draw" className="form-check-input" onChange={this.handleChange}/></div>
                                <div className="col-10"><label>Draw Date</label></div>
                            </div>
                        </div>
                    </div>


                </div>
                <div className="form-group" style={{ marginTop: '10px' }}>
                    
                {this.state.show ?  <Spinner animation="border" variant="success" /> : 
                    <button id="loginBtn" type="submit" value="submit" onClick={() => {
                        this.findbet();
                    }} className="btn btn-primary btn-lg btn-block" tabindex="4" style={{ backgroundColor: '#0b9c29' }} >
                        Submit
                    </button>
                }
                    <div><h5 id="message" style={{ color: 'red' }}>{this.state.error == null ? "" : this.state.error}</h5></div>
                </div>
            </div>
        </div>);
    }
}



export default Receipt;