import React from 'react';
import { Component } from 'react';
import './App.css';
import './../assets/modules/bootstrap/css/bootstrap.css';
import './../assets/modules/bootstrap/css/bootstrap.min.css';
import './../assets/modules/fontawesome/css/all.min.css';
import './../assets/modules/bootstrap-social/bootstrap-social.css';
import './../mobile.css';
import './../assets/css/components.css';
import './../assets/css/components.min.css';
import {back, URL} from './General';

class Password extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: "",
        };

        this.handleChange = this.handleChange.bind(this);
        this.updatePass = this.updatePass.bind(this);

    }

    handleChange(event) {
        this.setState({ [event.target.name] : event.target.value});
    }

    render() {
        return(<div>
            <div className="banner">
                <p>Password</p>
            </div>
            <div className="container" style={{ margin: '10px' }}>
                <div className="row" >
                    <div className="col">
                        <button variant="secondary" onClick={() => { 
                            back();
                        }}>Back</button>
                    </div>
                </div>
            </div>
            <div className="container mt-5">
                <div className="card card-primary" style={{borderRadius: '10px'}}>

                    <div className="card-body">
                        <div className="row" style={{justifyContent: 'center', marginBottom: '20px'}}>
                            <div className="col">
                                <input type="password" name="old_pwd"  placeholder="Old" value={this.state.old_pwd} onChange={this.handleChange}/>
                            </div>
                        </div>
                        <div className="row" style={{justifyContent: 'center', marginBottom: '20px'}}>
                            <div className="col">
                                <input type="password" name="new_pwd" placeholder="New" value={this.state.new_pwd} onChange={this.handleChange}/>
                            </div>
                        </div>
                        <div className="row" style={{justifyContent: 'center', marginBottom: '20px'}}>
                            <div className="col">
                                <input type="password" name="confrim" placeholder="Verify" value={this.state.confrim} onChange={this.handleChange}/>
                            </div>
                        </div>
                    </div>
                </div>
                <h5 style={{ color: 'red' }}>{this.state.error} </h5>
                <h5 style={{ color: 'green' }}>{this.state.success} </h5>
            </div>
            <div className="container" style={{marginTop:'20px'}}>
                <button className="btn btn-primary btn-lg btn-block" style={{backgroundColor: '#0b9c29'}} onClick={this.updatePass}>
                    Submit
                </button>
            </div>
        </div>);
    }

    updatePass(){
        const body = {
            action :"updatePassword",
            uid: sessionStorage.getItem("user"),
            old_pwd : this.state.old_pwd,
            new_pwd :this.state.new_pwd,
            new_pwd2:this.state.confrim
          };
     
        fetch(URL, {
                method: 'POST',
                body:    JSON.stringify(body)
            }).then(res => res.json())
            .then(json => {
                if(json.success){
                    this.setState({success : "Password Updated", error : ""})
                }
                else{
                    this.setState({error : json.error.msg, success: ""})
                }
            })
            .catch(e =>console.log(e));
    }
}

export default Password;