import React from 'react';
import { Component } from 'react';
import './App.css';
import './../assets/modules/bootstrap/css/bootstrap.css';
import './../assets/modules/bootstrap/css/bootstrap.min.css';
import './../assets/modules/fontawesome/css/all.min.css';
import './../assets/modules/bootstrap-social/bootstrap-social.css';
import './../mobile.css';
import './../assets/css/components.css';
import './../assets/css/components.min.css';
import { back, URL } from './General';
import Common from './Common';
import ReactDOM from 'react-dom';

//import MetaTags from 'react-meta-tags';

//const fetch = require('node-fetch');

class Reports extends Component {
    constructor(props) {
        super(props);
        this.state = {
            appmsg: "",
            date: "",
        };

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        this.setState({ [event.target.name] : event.target.value});
    }

    render() {
        return(<div>
            <div className="banner">
                <p>Reports</p>
            </div>
            <div className="container" style={{ margin: '10px' }}>
                <div className="row" >
                    <div className="col">
                        <button variant="secondary" onClick={() => { 
                            back();
                        }}>Back</button>
                    </div>
                </div>
            </div>
            <div className="container mt-5">
                <div className="form-group" style={{marginTop: '10px'}}>
                    <div className=" row">
                        <div className="col-3">
                            <label style={{fontSize: '15pt', fontWeight: '500'}}>Date : </label>
                        </div>
                        <div className="col-9">
                            <input type="date" name="date" className="form-control" value={ this.state.date } onChange={this.handleChange}/>
                        </div>
                    </div>
                </div>
                <h5 ></h5>
                <button id="loginBtn" className="btn btn-primary btn-lg btn-block" style={{backgroundColor: '#0b9c29'}} onClick={()=> this.report()}>
                    Submit
                </button>
                    <div><h5 id="message" style={{ color: 'red' }}>{this.state.error == null ? "" : this.state.error}</h5></div>
            </div>
        </div>);
    }

    report(){
        const body = {
            action : "findAgentReport",
            date : this.state.date,
            uid: sessionStorage.getItem("user")
          };
        fetch(URL, {
                method: 'POST',
                body:    JSON.stringify(body)
            }).then(res => res.json())
            .then(json => {
                if(json.success){
                    this.setState({error : null});
                    ReactDOM.render(
                        <Common msg={this.format(json.data[0])}/>,
                        document.getElementById('root')
                    );
                }
                else{
                    this.setState({error : "Failed, Please Try Again"});
                }
            })
            .catch(e => console.log(e));
    }

    format(data){
        var result = "";
        var ord = Number(data.sales);
        var net = ord - Number(data.commission);
        var win = Number(data.strike);
        var bal = net - win;

        result += "ACCOUNT : "+ data.username +"<br/>";
        result += "------------------------------------------------<br/>";
        result += "D.DATE : "+ data.start_date +"<br/>";
        result += "Ord : "+ ord.toFixed(2) +"<br/>";
        result += "Net : "+ net.toFixed(2) +"<br/>";
        result += "Win : "+ win.toFixed(2) +"<br/>";
        result += "**********************<br/>";
        result += "Bal : "+ bal.toFixed(2) +"<br/>";
        result += "**********************<br/>";
        return result;
    }
}

export default Reports;