import React from 'react';
import { Component } from 'react';
import './App.css';
import './../assets/modules/bootstrap/css/bootstrap.css';
import './../assets/modules/bootstrap/css/bootstrap.min.css';
import './../assets/modules/fontawesome/css/all.min.css';
import './../assets/modules/bootstrap-social/bootstrap-social.css';
import './../mobile.css';
import './../assets/css/components.css';
import './../assets/css/components.min.css';
import {back, URL, format, debug} from './General';
import Common from './Common';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import Spinner from 'react-bootstrap/Spinner';

class Buy extends Component {
    constructor(props) {
        super(props);
        this.state = {
            betstring: "",
            text1 : "D",
            text2 : "#",
            valid : false,
            amount : 0,
            show: false
        };

        if(props.buystring != null || props.buystring != undefined){
            var day = props.buystring.split(")")[0].substring(1);
            var drawletters = props.buystring.split("]")[0].split("[")[1];
            var bet = [];
            var bets = props.buystring.split("}");
            for(var i=0; i<bets.length;i++){
                bet.push(bets[i].split("{")[1]);
            }

            this.state.text1 = day;
            this.state.text2 = getdrawtypeNumber(drawletters);
            for(var j=0; j<bet.length;j++){
                this.state["text"+(j+3)] = bet[j];
            }
        }

        this.handleChange = this.handleChange.bind(this);
        this.verify = this.verify.bind(this);
        this.pasteHandler = this.pasteHandler.bind(this);

    }

    componentDidMount(){
        if(this.props.buystring != null || this.props.buystring != undefined){
            this.verify(false);
        }
    }

    handleChange(event) {
        this.setState({ [event.target.name] : event.target.value},() => this.verify(false));
    }

    pasteHandler(e){
        var clip = e.clipboardData.getData('text/plain');
        if(clip != ""){
            var line = clip.split("\n");
            if(line.length > 1){
                for(var i = 0; i<line.length;i++){
                    this.setState({["text"+(i+3)] : line[i]})
                }
                e.preventDefault();
            }
            
        }
    }


    render() {
        return (<div>
            <div className="banner">
                <p>Buy</p>
            </div>
            <div className="container" style={{ margin: '10px' }}>
                <div className="row" >
                    <div className="col">
                        <button variant="secondary" onClick={() => {
                            back();
                        }}>Back</button>
                    </div>
                </div>
            </div>
            <div className="container">
                <h3>Sum : {this.state.amount}</h3>
                <h5 style={{color : 'red'}}>{this.state.error}</h5>
            </div>
            <div className="container mt-5">
                <div className="card card-primary" style={{ borderRadius: '10px' }}>

                    <div className="card-body">

                        <div className="row" style={{ justifyContent: 'center', marginBottom: '10px' }}>
                            <div className="col-2" style={{ marginTop: '5px' }}>
                                <p>01</p>
                            </div>
                            <div className="col">
                                <input name="text1" type="text" value={this.state.text1} onChange={this.handleChange} autocomplete="off"/>
                            </div>
                        </div>
                        <div className="row" style={{ justifyContent: 'center', marginBottom: '10px' }}>
                            <div className="col-2" style={{ marginTop: '5px' }}>
                                <p>02</p>
                            </div>
                            <div className="col">
                                <input name="text2" type="text" value={this.state.text2} onChange={this.handleChange} autocomplete="off"/>
                            </div>
                        </div>
                        <div className="row" style={{ justifyContent: 'center', marginBottom: '10px' }}>
                            <div className="col-2" style={{ marginTop: '5px' }}>
                                <p>03</p>
                            </div>
                            <div className="col">
                                <input name="text3" onPaste={this.pasteHandler} type="text" value={this.state.text3} onChange={this.handleChange} autocomplete="off"/>
                            </div>
                        </div>
                        <div className="row" style={{ justifyContent: 'center', marginBottom: '10px' }}>
                            <div className="col-2" style={{ marginTop: '5px' }}>
                                <p>04</p>
                            </div>
                            <div className="col">
                                <input name="text4"  onPaste={this.pasteHandler} type="text" value={this.state.text4} onChange={this.handleChange} autocomplete="off"/>
                            </div>
                        </div>
                        <div className="row" style={{ justifyContent: 'center', marginBottom: '10px' }}>
                            <div className="col-2" style={{ marginTop: '5px' }}>
                                <p>05</p>
                            </div>
                            <div className="col">
                                <input name="text5"  onPaste={this.pasteHandler} type="text" value={this.state.text5} onChange={this.handleChange} autocomplete="off"/>
                            </div>
                        </div>
                        <div className="row" style={{ justifyContent: 'center', marginBottom: '10px' }}>
                            <div className="col-2" style={{ marginTop: '5px' }}>
                                <p>06</p>
                            </div>
                            <div className="col">
                                <input name="text6"  onPaste={this.pasteHandler} type="text" value={this.state.text6} onChange={this.handleChange} autocomplete="off"/>
                            </div>
                        </div>
                        <div className="row" style={{ justifyContent: 'center', marginBottom: '10px' }}>
                            <div className="col-2" style={{ marginTop: '5px' }}>
                                <p>07</p>
                            </div>
                            <div className="col">
                                <input name="text7"  onPaste={this.pasteHandler} type="text" value={this.state.text7} onChange={this.handleChange} autocomplete="off"/>
                            </div>
                        </div>
                        <div className="row" style={{ justifyContent: 'center', marginBottom: '10px' }}>
                            <div className="col-2" style={{ marginTop: '5px' }}>
                                <p>08</p>
                            </div>
                            <div className="col">
                                <input name="text8"  onPaste={this.pasteHandler} type="text" value={this.state.text8} onChange={this.handleChange} autocomplete="off"/>
                            </div>
                        </div>
                        <div className="row" style={{ justifyContent: 'center', marginBottom: '10px' }}>
                            <div className="col-2" style={{ marginTop: '5px' }}>
                                <p>09</p>
                            </div>
                            <div className="col">
                                <input name="text9"  onPaste={this.pasteHandler} type="text" value={this.state.text9} onChange={this.handleChange} autocomplete="off"/>
                            </div>
                        </div>
                        <div className="row" style={{ justifyContent: 'center', marginBottom: '10px' }}>
                            <div className="col-2" style={{ marginTop: '5px' }}>
                                <p>10</p>
                            </div>
                            <div className="col">
                                <input name="text10" type="text" value={this.state.text10} onChange={this.handleChange} autocomplete="off"/>
                            </div>
                        </div>
                    </div>
                </div>
               
            </div>



            <div className="container" style={{ margin: '10px' }}>
                <div className="row" >
                    <div className="col" align="end" style={{ marginRight: '15px' }}>
                        {this.state.show ?  <Spinner animation="border" variant="success" /> : 
                        <button className="btn btn-success" type="submit" value="submit" onClick={() => { this.verify(true); }}>Buy</button>
                    }
                    </div>
                    <br/>
                    <br/>
                </div>

            </div></div>);
    }

    buy(){
        if(this.state.valid){
            this.setState({show: true});
            const body = {
                action : "betNumber",
                betting_by : sessionStorage.getItem("user"),
                data : this.state.betstring,
                bet_amount : this.state.amount
              };
         
            fetch(URL, {
                    method: 'POST',
                    body:    JSON.stringify(body)
                }).then(res => res.json())
                .then(json => {
                    if(json.success){
                        this.setState({error : ""});
                        ReactDOM.render(
                            <Common msg={format(json)[json.data[0].receipt_number].receipt}/>,
                            document.getElementById('root')
                        );
                    }
                    else{
                        this.setState({show: false, error : "Failed, Please Try Again. { " + json.error.msg + " }"});
                    }
                })
                .catch(e =>console.log(e));
        }
    }

    verify(toBuy){
        var day = this.state['text1'];
        if(day == "") {
            this.setState({error : "Fill in Day"});
            return;
        }
        if(day.startsWith("D")){
            if(isNaN(day.split("D")[1])){
                this.setState({error : "Invalid Character in Day"});
                return;
            }else if(day.split("D")[1] > 9){
                this.setState({error : "Day cannot more than 9"});
                return;
            }else {
                if(day == "D"){
                    day = "D1";
                }
            }
        }

        var draw = this.state['text2'];
        if(draw.startsWith("#")) {
            draw = draw.substring(1);
            if(draw == "" || draw == undefined) {
                this.setState({error : "Please fill Row 2"});
                return;
            }else{
                var tempV = "";
                var nparts = draw.split("");
                for(var nump of nparts){
                    if(tempV.includes(nump)){
                        this.setState({ error : "Duplicate Draw Type Entry." });
                        return;
                    }
                    tempV += nump;
                }
                var converted = getdrawtype(draw);
                if(converted == undefined) {
                    this.setState({error : "Invalid Character on row 2"});
                    return;
                }
                draw = converted;
            }
        }
        else{
            this.setState({error : "# is missing"});
            return;
        }

        var amt = 0;
        var numbers = [];
        var error = "";
        
        for(var i = 3; i <11; i++){
            var numcol = this.state['text'+i];
            if(numcol == undefined && i == 3) {
                error = "Please fill at least 1 bet at row "+i;
                break;
            }
            if(numcol != undefined && numcol != ""){
                if(numcol.includes("#")){
                    var parts = numcol.split("#");
                    for( var part of parts ){
                        if(isNaN(part)){
                            error = "Invalid bet on row " + i;
                            break;
                        }
                    }

                    if(numcol.endsWith("#")){
                        error = "Invalid bet on row " + i + ". Please Specify the value";
                        break;
                    }else{
                        error = "";
                    }
                    
                    var num = parts[0];

                    var type = "normal";
                    if(num.startsWith('*')){
                        type = "pau";
                        num = num.substring(1);
                        if(num.startsWith('*')){
                            type = "ibox";
                            num = num.substring(1);
                        }
                    }
                    if(type == "normal"){
                        if(num.endsWith("**")){
                            type = "a4";
                            num = num.substring(0, (num.length)-2);
                        }
                        if(num.endsWith("*")){
                            error = "Invalid bet on row " + i;
                            break;
                        }
                    }else{
                        error = "Invalid bet on row " + i + ". 4A cannot be pau or IBOX";
                        break;
                    }

                    var regex = new RegExp('^[0-9]+$');
                    if(!regex.test(num)){
                        error = "Invalid character in bet on row " + i;
                        break;
                    }

                    if(num.length > 6 || num.length < 3){
                        error = "Invalid bet number. Number should less than 7 and more than 2 at Row " + i;
                        break;
                    }
                    var hashCount = (numcol.match(/#/g)).length;
                    if(num.length == 4){
                        if( type == "a4"){
                            if(hashCount > 5){
                                error = "Invalid bet on row " + i;
                                break;
                            }
                        }else{
                            if(hashCount > 2){
                                error = "Invalid bet on row " + i;
                                break;
                            }
                        }
                        
                    }else{
                        if(type == "pau" || type == "ibox" || type == "a4"){
                            error = "Invalid bet on row " + i + ". Non 4D number are not valid for IBOX, Pau, or 4A";
                            break;
                        }
                        if(num.length == 3){
                            if(hashCount > 2){
                                error = "Invalid bet on row " + i;
                                break;
                            }
                        }
                        if(num.length == 5 || num.length == 6){
                            if(hashCount > 1){
                                error = "Invalid bet on row " + i;
                                break;
                            }
                            if(!draw.includes("T")){
                                error = "Invalid bet on row " + i + ". " + num.length + "D number are not valid for Chosen Bet Type.";
                                break;
                            }
                        }
                    }
                    for(var j=1; j < (hashCount+1); j++){
                        var nm = Number(parts[j]);
                        if(isNaN(nm)){
                            error = "Invalid character in bet on row " + i;
                            return;
                        }
                        if(type == "pau"){
                            var by = stringPermutations(num).length;
                            amt += nm * by;
                        }else{
                            amt += nm ;
                        }
                    }
                    numbers.push("{" + numcol + "}");
                    error = "";
                }else{
                    error = "Invalid bet on row " + i;
                    break;
                }
            }
        }

        //times by day
        amt =  amt * Number(day.split("D")[1]); 
        // times by draw num
        amt =  amt * Number(draw.split("").length); 
        
        var str = "("+day+")["+draw+"]";
        for(var num of numbers){
            str += num;
        }

        if(error != "" ){
            this.setState({valid : false, error : error});
            return;
        }

        if(numbers.length > 0 && error == ""){
            this.setState({valid : true, betstring: str, amount : amt, error : error}, () => {  if(toBuy) this.buy();});
        }
        if(debug){
            console.log("error down = > " , this.state.error);
            console.log("str => " , str);
        }
    }
}

function getdrawtype(draw){
    var converted = "";
    var parts = draw.split("");
    for(var num of parts){
        switch (num) {
            case "1":
                converted += "M"
                break;
            case "2":
                converted += "P"
                break;
            case "3":
                converted += "T"
                break;
            case "4":
                converted += "S"
                break;
            case "5":
                converted += "B"
                break;
            case "6":
                converted += "W"
                break;
            case "7":
                converted += "K"
                break;
            case "8":
                converted += "H"
                break;
            case "9":
                converted += "N"
                break;
            default:
                return undefined;
        }
    }
    return converted;
}  

function getdrawtypeNumber(draw){
    var converted = "";
    var parts = draw.split("");
    for(var num of parts){
        switch (num) {
            case "M":
                converted += "1"
                break;
            case "P":
                converted += "2"
                break;
            case "T":
                converted += "3"
                break;
            case "S":
                converted += "4"
                break;
            case "B":
                converted += "5"
                break;
            case "W":
                converted += "6"
                break;
            case "K":
                converted += "7"
                break;
            case "H":
                converted += "8"
                break;
            case "N":
                converted += "9"
                break;
            default:
                return undefined;
        }
    }
    return converted;
} 

function stringPermutations(str) {
    let letters = str.split('')
      , results = [[letters.shift()]] 
    while (letters.length) {
        const currLetter = letters.shift()
        let tmpResults = []
        results.forEach(result => {
            let rIdx = 0
            while (rIdx <= result.length) {
                const tmp = [...result]
                tmp.splice(rIdx, 0, currLetter)
                tmpResults.push(tmp)
                rIdx++
            }
        })
        results = tmpResults
    }
    return results
      .map(letterArray => letterArray.join(''))
      .filter((el, idx, self) => (self.indexOf(el) === idx))
      .sort()
}
export default Buy;