import React from 'react';
import { Component } from 'react';
import './App.css';
import './../assets/modules/bootstrap/css/bootstrap.css';
import './../assets/modules/bootstrap/css/bootstrap.min.css';
import './../assets/modules/fontawesome/css/all.min.css';
import './../assets/modules/bootstrap-social/bootstrap-social.css';
import './../mobile.css';
import './../assets/css/components.css';
import './../assets/css/components.min.css';
import buypic from './../mobile-asset/buy.png';
import receipt from './../mobile-asset/receipt.png';
import result from './../mobile-asset/result.png';
import strike from './../mobile-asset/strike.png';
import report from './../mobile-asset/report.png';
import pass from './../mobile-asset/password.png';
import help from './../mobile-asset/help.png';
import ReactDOM from 'react-dom';

import Buy from './Buy';
import Receipt from './Receipt';
import Result from './Result';
import Strike from './Strike';
import Reports from './Reports';
import Password from './Password';
import Help from './Help';
import {logout, URL} from './General';
//import MetaTags from 'react-meta-tags';

const fetch = require('node-fetch');

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            appmsg: "",
        };

        const body = {
            "action":"appScrollMsg"
          };
    
        fetch(URL, {
            method: 'POST',
            body:    JSON.stringify(body)
            }).then(res => res.json())
            .then(json => {
                if(json.success){
                    var msg = "";
                    for(var e of json.data){
                        msg += " -- "+e.msg;
                    }
                    this.setState({appmsg : msg});
                }
            })
            .catch(e =>console.log(e));
    }

    render() {
        return ( <div style={{ backgroundColor:'#bebebe' }}>
             <div className="banner">
                <p>Dashboard</p>
            </div>
        <div>
            <marquee scrollamount="10">
                <h3>{this.state.appmsg}</h3>
            </marquee>
        </div>
        <div style={{alignItems:'left'}}>
            <div className="container" style={{ margin: '6px' }}>
                <div className="row" >
                    <div className="col">
                        <h3><span class="badge badge-warning">{ sessionStorage.getItem('name') }</span></h3>
                    </div>
                </div>
                <div className="row" >
                     <div className="col">
                        <button className="btn btn-outline-danger btn-sm" onClick={() => { 
                            logout();
                        }}>Logout</button>
                    </div>
                </div>
            </div>
        </div>
        <div className="container mt-5" >
            <div className="card card-primary" style={{borderRadius: '20px'}}>
    
                <div className="card-body">
                    <div>
                    <div className="row clickable" onClick={() => page("buy")}>
                            <div className="col-3">
                                <img src={ buypic } alt="logo" width="60"/>
                            </div>
                            <div className="col-9" style={{alignSelf: 'center'}}>
                                <h4>Buy</h4>
                            </div>
                            <hr/>
                        </div>
                        <div className="row clickable" onClick={() => page("receipt")}>
                            <div className="col-3">
                                <img src={ receipt } alt="logo" width="60"/>
                            </div>
                            <div className="col-9" style={{alignSelf: 'center'}}>
                                <h4>Receipt / Void</h4>
                            </div>
                            <hr/>
                        </div>
                        <div className="row clickable" onClick={() => page("result")}>
                            <div className="col-3">
                                <img src={result} alt="logo" width="60"/>
                            </div>
                            <div className="col-9" style={{alignSelf: 'center'}}>
                                <h4>Result</h4>
                            </div>
                            <hr/>
                        </div>
                        <div className="row clickable" onClick={() => page("strike")}>
                            <div className="col-3">
                                <img src={strike} alt="logo" width="60"/>
                            </div>
                            <div className="col-9" style={{alignSelf: 'center'}}>
                                <h4>Strike</h4>
                            </div>
                            <hr/>
                        </div>
                        <div className="row clickable" onClick={() => page("report")}>
                            <div className="col-3">
                                <img src={report} alt="logo" width="60"/>
                            </div>
                            <div className="col-9" style={{alignSelf: 'center'}}>
                                <h4>Reports</h4>
                            </div>
                            <hr/>
                        </div>
                        <div className="row clickable" onClick={() => page("password")}>
                            <div className="col-3">
                                <img src={pass} alt="logo" width="60"/>
                            </div>
                            <div className="col-9" style={{alignSelf: 'center'}}>
                                <h4>Password</h4>
                            </div>
                            <hr/>
                        </div>
                        <div className="row clickable" onClick={() => page("help")}>
                            <div className="col-3">
                                <img src={help} alt="logo" width="60"/>
                            </div>
                            <div className="col-9" style={{alignSelf: 'center'}}>
                                <h4>Help</h4>
                            </div>
    
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>);
    }

}

function page(name){
    var next;
    switch(name){
        case "buy": 
            next = <Buy/> //buystring="(D1)[MPT]{3452#2}{3452#2}{3452#2}{3452#2}"/>
            break;
        case "receipt": 
            next = <Receipt/>
            break;
        case "result": 
            next = <Result/>
            break;
        case "strike": 
            next = <Strike/>
            break;
        case "report": 
            next = <Reports/>
            break;
        case "password": 
            next = <Password/>
            break;
        case "help": 
            next = <Help/>
            break;
        default:
            break;
    }
    if(next != null){
        ReactDOM.render(
            next,
            document.getElementById('root')
        );
    }
}


export default Dashboard;
