import React from 'react';
import { Component } from 'react';
import './App.css';
import './../assets/modules/bootstrap/css/bootstrap.css';
import './../assets/modules/bootstrap/css/bootstrap.min.css';
import './../assets/modules/fontawesome/css/all.min.css';
import './../assets/modules/bootstrap-social/bootstrap-social.css';
import './../mobile.css';
import './../assets/css/components.css';
import './../assets/css/components.min.css';
import { back, getbet, URL } from './General';
import Common from './Common';
import ReactDOM from 'react-dom';

class Strike extends Component {
	constructor(props) {
		super(props);
		this.state = {
			appmsg: '',
		};
		this.handleChange = this.handleChange.bind(this);
	}

	handleChange(event) {
		this.setState({ [event.target.name]: event.target.value });
	}
	render() {
		return (
			<div>
				<div className='banner'>
					<p>Strike</p>
				</div>
				<div className='container' style={{ margin: '10px' }}>
					<div className='row'>
						<div className='col'>
							<button
								variant='secondary'
								onClick={() => {
									back();
								}}>
								Back
							</button>
						</div>
					</div>
				</div>
				<div className='container mt-5'>
					<div className='card card-primary' style={{ borderRadius: '10px' }}>
						<div className='card-body'>
							<input type='date' name='date' className='form-control' value={this.state.date} onChange={this.handleChange} />
						</div>
					</div>

					<button
						id='loginBtn'
						className='btn btn-primary btn-lg btn-block mt-3'
						style={{ backgroundColor: '#0b9c29' }}
						onClick={() => this.strike()}>
						Submit
					</button>
					<div>
						<h5 id='message' style={{ color: 'red' }}>
							{this.state.error == null ? '' : this.state.error}
						</h5>
					</div>
				</div>
			</div>
		);
	}

	strike() {
		const body = {
			action: 'findWinningData',
			requested_date: this.state.date,
			uid: sessionStorage.getItem('user'),
		};
		fetch(URL, {
			method: 'POST',
			body: JSON.stringify(body),
		})
			.then((res) => res.json())
			.then((json) => {
				if (json.success) {
					this.setState({ error: null });
					console.log(json);
					ReactDOM.render(<Common msg={this.format(json)} />, document.getElementById('root'));
				} else {
					this.setState({ error: 'No strike found' });
				}
			})
			.catch((e) => console.log(e));
	}

	format(res) {
		var dict = {};
		var result = '';
		for (var r of res.data) {
			//make receipt number findable
			if (!dict[r.receipt_number]) {
				dict[r.receipt_number] = [];
			}
			dict[r.receipt_number].push(r);
		}

		for (var rNum in dict) {
			var rec = dict[rNum];
			result += rec[0].username + '<br/>';
			result += rec[0].betting_date.split(' ')[0] + '<br/>';
			result += rNum + '<br/>';
			for (var st of rec) {
				var sym = st.draw_symbol.startsWith('_') ? 'T' : st.draw_symbol;
				result += sym + st.placing_position + getbet(st) + st.betting_number + '<br/>';
				result += getVal(st);
				result += '<br/><br/>';
			}
			result += '------------------------------------------------<br/>';
		}
		return result;
	}
}

function getVal(er) {
	var line = '';
	if (er.big_amount != 0) {
		line += 'B' + Number(er.big_amount).toFixed(2);
		line += '   $' + Number(er.big_payout).toFixed(2);
		line += '&nbsp;&nbsp;';
	}
	if (er.small_amount != 0) {
		line += 'S' + Number(er.small_amount).toFixed(2);
		line += '   $' + Number(er.small_payout).toFixed(2);
		line += '&nbsp;&nbsp;';
	}
	if (er.A4_amount != 0) {
		line = Number(er.A4_amount).toFixed(2);
		line += '   $' + Number(er.A4_payout).toFixed(2);
		line += '&nbsp;&nbsp;';
	}
	if (er.A_amount != 0) {
		line = Number(er.A_amount).toFixed(2);
		line += '   $' + Number(er.A_payout).toFixed(2);
		line += '&nbsp;&nbsp;';
	}
	if (er.ABC_amount != 0) {
		line = Number(er.ABC_amount).toFixed(2);
		line += '   $' + Number(er.ABC_payout).toFixed(2);
		line += '&nbsp;&nbsp;';
	}
	if (er.D5_amount != 0) {
		line = Number(er.D5_amount).toFixed(2);
		line += '   $' + Number(er.D5_payout).toFixed(2);
		line += '&nbsp;&nbsp;';
	}
	if (er.D6_amount != 0) {
		line = Number(er.D6_amount).toFixed(2);
		line += '   $' + Number(er.D6_payout).toFixed(2);
		line += '&nbsp;&nbsp;';
	}

	return line;
}

export default Strike;
